import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useContext, useEffect, useMemo } from "react";
import { fetchGet, LayoutContext } from "wcz-layout";
import { GridToolbar, GridToolbarProps, TableContainer } from 'wcz-x-data-grid';
import { MaterialCategory } from "../../models/enums/MaterialCategory";
import { eqOrderUrl } from "../../utils/BaseUrl";

export default function ReceivingReport() {
    const { changeTitle, t } = useContext(LayoutContext);

    useEffect(() => changeTitle("Receiving Report"), []);

    const { data = [], isLoading } = useQuery(["receivingReport"], ({ signal }) => fetchGet(`${eqOrderUrl}/v1/report/receiving`, signal));

    const columns: GridColDef[] = useMemo(() => [
        { field: 'partNumber', headerName: "PN", width: 250 },
        { field: 'locations', headerName: t("Locations"), width: 350 },
        { field: 'category', headerName: t("Category"), width: 160, type: 'singleSelect', valueOptions: Object.values(MaterialCategory), },
        { field: 'description', headerName: t("Description"), width: 400 },
        { field: 'quantity', headerName: t("Quantity"), width: 150, type: 'number' },
        { field: 'date', headerName: t("Date"), width: 200, type: 'date', valueFormatter: ({ value }) => moment(value).formatDate(), valueGetter: ({ value }) => value && new Date(value) },
        { field: 'serialNumber', headerName: "SN", width: 250 },
    ] as GridColDef[], [t]);

    return (
        <TableContainer>
            <DataGridPremium rows={data} columns={columns} slots={{ toolbar: GridToolbar }} slotProps={{ toolbar: { viewKey: "receivingReport", export: true, } as GridToolbarProps }} loading={isLoading} />
        </TableContainer>
    );
}