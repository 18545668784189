import { Add } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { DataGridPremium, GridColDef, GridFilterModel, GridPaginationModel, GridRowId, GridSortModel } from "@mui/x-data-grid-premium";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { fetchDelete, fetchPost, LayoutContext } from "wcz-layout";
import { GridDeleteCellItem, GridToolbar, GridToolbarProps, TableContainer } from 'wcz-x-data-grid';
import CreateMaterialDialog from "../../components/material/CreateMaterialDialog";
import EditMaterialDialog from "../../components/material/EditMaterialDialog";
import PaginationFilter from "../../models/base/PaginationFilter";
import PaginationResponse from "../../models/base/PaginationResponse";
import { MaterialCategory } from "../../models/enums/MaterialCategory";
import Location from "../../models/Location";
import Material from "../../models/Material";
import Model from "../../models/Model";
import { eqRoomMaterialUrl } from "../../utils/BaseUrl";
import { getAdvancedFilter, getKeyword, getOrderBy } from "../../utils/ServerSide";

export default function Materials() {
    const [materials, setMaterials] = useState<Material[]>([]);
    const [serverSide, setServerSide] = useState<PaginationFilter>({ pageNumber: 0, pageSize: 100 });
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const [editId, setEditId] = useState<string>("");
    const { changeTitle, t, i18n } = useContext(LayoutContext);

    const { data, isFetching, refetch } = useQuery<PaginationResponse<Material>>(["material", "search"], () => fetchPost(`${eqRoomMaterialUrl}/v1/material/search?alternatives=true`, serverSide), {
        enabled: !!serverSide,
        onSuccess: data => {
            setMaterials(data.content);
        }
    });

    useEffect(() => changeTitle("PN"), []);

    const remove = useMutation((id: GridRowId) => fetchDelete(`${eqRoomMaterialUrl}/v1/material/${id}`));

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'id', type: 'actions', width: 50,
            getActions: (params: any) => [
                <GridDeleteCellItem key="delete" id={params.id} remove={remove} showInMenu />
            ]
        },
        { field: 'partNumber', headerName: "PN", width: 220 },
        { field: 'category', headerName: t("Category"), width: 160, type: 'singleSelect', valueOptions: Object.values(MaterialCategory), sortable: false, },
        { field: 'subCategory', headerName: t("SubCategory"), width: 150, valueGetter: ({ value }) => value?.name, sortable: false, },
        { field: 'description', headerName: t("Description"), width: 380, },
        { field: 'type', headerName: t("Type"), width: 150, },
        {
            field: 'models', headerName: t("Models"), width: 400, valueGetter: ({ value }) => value?.map((v: Model) => v.name), sortable: false,
            renderCell: ({ value }) => !!value?.length && value.map((m: string, i: number, arr: string[]) => <Chip key={m} size="small" label={m} sx={{ mr: (i + 1) === arr.length ? undefined : 1 }} />)
        },
        { field: 'isReturnable', headerName: t("IsReturnable"), width: 120, type: 'boolean' },
        { field: 'usableQuantity', headerName: t("UsableQuantity"), width: 130, type: 'number', sortable: false },
        { field: 'totalQuantity', headerName: t("TotalQuantity"), width: 130, type: 'number', sortable: false },
        { field: 'safetyStock', headerName: t("SafetyStock"), width: 130, type: 'number', },
        { field: 'hasItems', headerName: t("HasSn"), width: 110, type: 'boolean', },
        { field: 'vendor', headerName: t("Vendor"), width: 150, },
        { field: 'vendorPartNumber', headerName: `${t("Vendor")} PN`, width: 170, },
        {
            field: 'locations', headerName: t("Locations"), width: 250, valueGetter: ({ value }) => value?.map((l: Location) => l.name), sortable: false,
            renderCell: ({ value }) => value?.map((l: string, i: number, arr: string[]) => <Chip key={l} size="small" label={l} sx={{ mr: (i + 1) === arr.length ? undefined : 1 }} />)
        },
        { field: 'project', headerName: t("Project"), width: 170, },
        { field: 'remark', headerName: t("Remark"), width: 350 },
        { field: 'unitPrice', headerName: t("UnitPrice"), width: 120, type: "number" },
    ] as GridColDef[], [i18n.language]);

    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        setServerSide({
            ...serverSide,
            keyword: getKeyword(filterModel.quickFilterValues),
            advancedFilter: getAdvancedFilter(filterModel)
        });

        setTimeout(() => refetch(), 300);
    }, [serverSide]);

    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        setServerSide({
            ...serverSide,
            orderBy: getOrderBy(sortModel)
        });

        setTimeout(() => refetch(), 300);
    }, [serverSide]);

    const handlePaginationModelChange = useCallback((model: GridPaginationModel) => {
        setServerSide({
            ...serverSide,
            pageNumber: model.page,
            pageSize: model.pageSize
        });

        setTimeout(() => refetch(), 300);
    }, [serverSide]);

    return (
        <React.Fragment>
            <TableContainer>
                <DataGridPremium rows={materials} columns={columns} components={{ Toolbar: GridToolbar }} editMode="row" loading={isFetching} onRowDoubleClick={params => setEditId(params.id as string)}
                    filterMode="server" onFilterModelChange={onFilterChange}
                    sortingMode="server" onSortModelChange={handleSortModelChange}
                    pagination paginationModel={{ page: serverSide.pageNumber ?? 0, pageSize: serverSide.pageSize ?? 100 }} paginationMode="server" onPaginationModelChange={handlePaginationModelChange} rowCount={data?.totalElements ?? 0} pageSizeOptions={[]} componentsProps={{
                        toolbar: {
                            items: [
                                { title: t("DataGrid.AddRecord"), icon: <Add />, onClick: () => setCreateOpen(true) },
                            ]
                        } as GridToolbarProps
                    }}
                />
            </TableContainer>

            <CreateMaterialDialog open={createOpen} onClose={() => setCreateOpen(false)} refetch={refetch} />
            <EditMaterialDialog materialId={editId} setMaterialId={setEditId} refetch={refetch} />
        </React.Fragment>
    );
}