import { Close, Dashboard, Favorite, History, Search, ShoppingCart, Lock } from '@mui/icons-material';
import { Autocomplete, Box, Button, Chip, Divider, Grid, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Switch, TextField, Typography } from '@mui/material';
import { DataGridPremium, GridActionsCellItem, GridColDef } from '@mui/x-data-grid-premium';
import { useQuery } from '@tanstack/react-query';
import { uniqBy } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { FileViewer } from 'wcz-file';
import { fetchGet, fetchPost, isMobile, LayoutContext, persistStorage } from 'wcz-layout';
import { TableContainer } from 'wcz-x-data-grid';
import CartButton from '../components/order/CartButton';
import StoreItemCard from '../components/store/StoreItemCard';
import PaginationFilter, { Filter } from '../models/base/PaginationFilter';
import PaginationResponse from '../models/base/PaginationResponse';
import { MaterialCategory } from '../models/enums/MaterialCategory';
import Location from "../models/Location";
import Material, { MaterialFilter } from '../models/Material';
import Model from '../models/Model';
import { OrderType } from '../models/order/OrderType';
import { eqOrderUrl, eqRoomMaterialUrl } from '../utils/BaseUrl';
import EmployeeLock from '../models/EmployeeLock';

const storedCardDisplay: string | null = persistStorage.get("cardDisplay");

export default function Home() {
    const [serverSide, setServerSide] = useState<PaginationFilter>({ pageNumber: 0, pageSize: 20 });
    const [materials, setMaterials] = useState<Material[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [searchText] = useDebounce(searchValue, 700);
    const [searchCategory, setSearchCategory] = useState<MaterialCategory>();
    const [searchSubCategory, setSearchSubCategory] = useState<string | null>(null);
    const [searchModel, setSearchModel] = useState<string | null>(null);
    const [favorites, setFavorites] = useState<boolean>(false);
    const [lastOrdered, setLastOrdered] = useState<boolean>(false);
    const [addMaterial, setAddMaterial] = useState<string>("");
    const { changeTitle, user, t } = useContext(LayoutContext);
    const [cardDisplay, setCardDisplay] = useState<boolean>(storedCardDisplay === "true");

    useQuery<Material[]>(["materials", "favorite"], ({ signal }) => fetchGet(`${eqOrderUrl}/v1/FavouriteMaterial?employeeId=${user.id}`, signal), {
        enabled: favorites,
        onSuccess: data => setMaterials(data)
    });

    useQuery<Material[]>(["materials", "lastOrdered"], ({ signal }) => fetchGet(`${eqOrderUrl}/v1/Order/lastOrderedMaterials/${user.id}`, signal), {
        enabled: lastOrdered,
        onSuccess: data => setMaterials(data)
    });

    const { data: employeeLock = { isEmployeeLocked: false } } = useQuery<EmployeeLock>(["employeeLock", user.id], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/cyclecount/employeeLock/${user.id}`, signal));

    const { data, refetch, isFetching } = useQuery<PaginationResponse<Material>>(["material", "search"], () => fetchPost(`${eqRoomMaterialUrl}/v1/material/search?alternatives=true`, serverSide), {
        onSuccess: data => setMaterials(data.content)
    });

    const { data: dataSuggestion = {} as MaterialFilter } = useQuery<MaterialFilter>(["materialFilter"], ({ signal }) => fetchGet(eqRoomMaterialUrl + "/v1/material/filter", signal));

    useEffect(() => changeTitle(t("Store")), []);

    useEffect(() => {
        let newServerSide: PaginationFilter = serverSide;

        if (searchText) {
            newServerSide = {
                ...newServerSide,
                keyword: searchText
            };
        }
        else {
            newServerSide.keyword = undefined;
        }

        setServerSide(newServerSide);
        setTimeout(() => refetch(), 300);
    }, [searchText]);

    useEffect(() => {
        let newFilters: Filter[] = serverSide.advancedFilter?.filters?.filter(filter => filter.field !== "category") ?? [];

        if (searchCategory)
            newFilters.push({
                field: "category",
                operator: "eq",
                value: searchCategory
            });

        setServerSide({ ...serverSide, advancedFilter: { logic: "and", filters: newFilters } });
        setTimeout(() => refetch(), 300);
    }, [searchCategory]);

    useEffect(() => {
        let newFilters: Filter[] = serverSide.advancedFilter?.filters?.filter(filter => filter.field !== "subCategory.name") ?? [];

        if (searchSubCategory)
            newFilters.push({
                field: "subCategory.name",
                operator: "eq",
                value: searchSubCategory
            });

        setServerSide({ ...serverSide, advancedFilter: { logic: "and", filters: newFilters } });
        setTimeout(() => refetch(), 300);
    }, [searchSubCategory]);

    useEffect(() => {
        let newFilters: Filter[] = serverSide.advancedFilter?.filters?.filter(filter => filter.field !== "models.name") ?? [];

        if (searchModel)
            newFilters.push({
                field: "models.name",
                operator: "eq",
                value: searchModel
            });

        setServerSide({ ...serverSide, advancedFilter: { logic: "and", filters: newFilters } });
        setTimeout(() => refetch(), 300);
    }, [searchModel]);

    const toggleFavorites = useCallback(() => { setFavorites(!favorites); setLastOrdered(false); favorites && refetch(); }, [favorites]);
    const toggleLastOrdered = useCallback(() => { setLastOrdered(!lastOrdered); setFavorites(false); lastOrdered && refetch(); }, [lastOrdered]);

    const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setSearchValue(e.target.value), []);
    const onSearchCategoryChange = useCallback((newCategory: MaterialCategory) => () => setSearchCategory(newCategory === searchCategory ? undefined : newCategory), [searchCategory]);

    const handleLoadMore = useCallback(() => {
        setServerSide({
            ...serverSide,
            pageSize: serverSide.pageSize! + 12
        });

        setTimeout(() => refetch(), 300);
    }, [serverSide]);

    const handleClearSearch = useCallback(() => setSearchValue(""), []);

    const handleOnCardDisplayChange = useCallback(() => {
        persistStorage.set("cardDisplay", String(!cardDisplay));
        setCardDisplay(!cardDisplay);
    }, [cardDisplay]);

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'menu', type: 'actions', width: 50,
            getActions: (params: any) => [<GridActionsCellItem key="materials" icon={<ShoppingCart />} label={t("Cart")} onClick={() => setAddMaterial(params.id)} />]
        },
        { field: 'images', headerName: "", width: 155, renderCell: ({ id }) => <FileViewer subId={String(id)} disableActions imageSize={60} /> },
        { field: 'subCategory', headerName: t("SubCategory"), width: 150, valueGetter: ({ value }) => value?.name, },
        { field: 'partNumber', headerName: "PN", width: 220, renderCell: ({ value }) => <Typography variant="body2" sx={{ my: 2 }}>{value}</Typography> },
        { field: 'type', headerName: t("Type"), width: 150, },
        { field: 'description', headerName: t("Description"), width: 380, },
        {
            field: 'locations', headerName: t("Locations"), width: 150, valueGetter: ({ value }) => value?.map((l: Location) => l.name).filter((name: string) => name !== "SCRAP" && name !== "HOLD"),
            renderCell: ({ value }) => !!value?.length &&
                <Grid container spacing={1} sx={{ mb: 1, mt: 0.3 }}>
                    {value?.map((name: string) =>
                        <Grid item key={name}>
                            <Chip size="small" label={name} />
                        </Grid>
                    )}
                </Grid>
        },
        { field: 'usableQuantity', headerName: "Qty", width: 100, type: 'number' },
        {
            field: 'models', headerName: t("Models"), width: 400, valueGetter: ({ value }) => value?.map((v: Model) => v.name),
            renderCell: ({ value }) => !!value?.length &&
                <Grid container spacing={1} sx={{ mb: 1, mt: 0.3 }}>
                    {value?.map((pn: string) =>
                        <Grid item key={pn}>
                            <Chip size="small" label={pn} />
                        </Grid>
                    )}
                </Grid>
        },
    ] as GridColDef[], []);

    if (employeeLock.isEmployeeLocked)
        return (
            <Box sx={{ position: "relative", height: { xs: "calc(100vh - 125px)", sm: "calc(100vh - 133px)" } }}>
                <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center" }}>
                    <Lock color="disabled" fontSize="large" />
                    <Typography variant="subtitle2" sx={{ opacity: 0.7 }}>{t("MaterialMovementIsSuspendedDuringYourInventory")}</Typography>
                </Box>
            </Box>
        );

    return (
        <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item md={3} lg={2}>
                <List sx={{ width: '100%' }} component="nav">
                    <TextField size="small" placeholder={t("Search")!} value={searchValue} onChange={onSearchChange} fullWidth autoFocus={!isMobile} sx={{ mb: 1 }} disabled={favorites || lastOrdered} InputProps={{
                        startAdornment:
                            <InputAdornment position="start">
                                {searchValue ? <Close sx={{ cursor: 'pointer' }} onClick={handleClearSearch} /> : <Search />}
                            </InputAdornment>,
                    }} />

                    <ListItem disableGutters disablePadding selected={favorites}>
                        <ListItemButton onClick={toggleFavorites}>
                            <ListItemIcon>
                                <Favorite />
                            </ListItemIcon>
                            <ListItemText primary={t("Favorites")} />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disableGutters disablePadding selected={lastOrdered}>
                        <ListItemButton onClick={toggleLastOrdered}>
                            <ListItemIcon>
                                <History />
                            </ListItemIcon>
                            <ListItemText primary={t("LastOrdered")} />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disableGutters disablePadding>
                        <ListItemButton onClick={handleOnCardDisplayChange}>
                            <ListItemIcon>
                                <Dashboard />
                            </ListItemIcon>
                            <ListItemText primary={t("CardDisplay")} />
                            <Switch edge="end" checked={cardDisplay} />
                        </ListItemButton>
                    </ListItem>

                    <Divider sx={{ my: 1 }} />

                    {Object.values(MaterialCategory).map(category =>
                        <ListItem disableGutters disablePadding key={category} selected={searchCategory === category} onClick={onSearchCategoryChange(category)} disabled={favorites || lastOrdered}>
                            <ListItemButton>
                                <ListItemText primary={category} />
                            </ListItemButton>
                        </ListItem>
                    )}

                    <Divider sx={{ my: 1 }} />

                    <Autocomplete
                        value={searchSubCategory}
                        options={uniqBy(dataSuggestion.subCategory, 'id').map(sc => sc.name) ?? []}
                        onChange={(e, value) => setSearchSubCategory(value)}
                        autoHighlight
                        disabled={favorites || lastOrdered}
                        renderInput={(params) => <TextField {...params} fullWidth label={t("SubCategory")} size="small" sx={{ my: 1 }} />}
                    />

                    <Autocomplete
                        value={searchModel}
                        options={dataSuggestion?.models?.map(m => m.name) ?? []}
                        onChange={(e, value) => setSearchModel(value)}
                        autoHighlight
                        disabled={favorites || lastOrdered}
                        renderInput={(params) => <TextField {...params} fullWidth label="Model" size="small" sx={{ my: 1 }} />}
                    />

                </List>
            </Grid>

            <Grid item md={9} lg={10}>
                <Grid container spacing={1}>
                    {cardDisplay ?
                        materials.map(material =>
                            <Grid item xs={12} md={6} lg={4} key={material.id}>
                                <StoreItemCard material={material} setAddMaterial={setAddMaterial} />
                            </Grid>)
                        :
                        <TableContainer height={{ xs: "calc(100vh - 186px)", sm: "calc(100vh - 165px)" }} width="100%">
                            <DataGridPremium columns={columns} rows={materials} getRowHeight={() => 'auto'}
                                onRowDoubleClick={params => setAddMaterial(params.id as string)} disableColumnMenu disableVirtualization />
                        </TableContainer>
                    }

                    {!data?.last && !favorites && !lastOrdered &&
                        <Grid item xs={12} sx={{ textAlign: 'center', my: 2 }}>
                            <Button variant="contained" onClick={handleLoadMore} disabled={isFetching}>{t("LoadMore")}</Button>
                        </Grid>
                    }
                </Grid>
            </Grid>

            <CartButton addMaterial={addMaterial} setAddMaterial={setAddMaterial} type={OrderType.Purchase} refetch={refetch} />
        </Grid>
    );
}