import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useContext, useEffect, useMemo } from "react";
import { fetchGet, LayoutContext } from "wcz-layout";
import { GridToolbar, GridToolbarProps, TableContainer } from 'wcz-x-data-grid';
import { MaterialCategory } from "../../models/enums/MaterialCategory";
import { eqOrderUrl } from "../../utils/BaseUrl";

export default function ConsumptionReport() {
    const { changeTitle, t } = useContext(LayoutContext);

    useEffect(() => changeTitle("Consumption Report"), []);

    const { data = [], isLoading } = useQuery(["consumptionReport"], ({ signal }) => fetchGet(`${eqOrderUrl}/v1/report/consumption`, signal));

    const columns: GridColDef[] = useMemo(() => [
        { field: 'partNumber', headerName: "PN", width: 250 },
        { field: 'category', headerName: t("Category"), width: 160, type: 'singleSelect', valueOptions: Object.values(MaterialCategory), },
        { field: 'description', headerName: t("Description"), width: 400 },
        { field: 'quantity', headerName: t("Quantity"), width: 150, type: 'number' },
        { field: 'orderedBy', headerName: t("Customer"), width: 200 },
        { field: 'customerDepartment', headerName: t("Department"), width: 200 },
        { field: 'date', headerName: t("Date"), width: 200, type: 'date', valueFormatter: ({ value }) => moment(value).formatDate(), valueGetter: ({ value }) => value && new Date(value) },
        { field: 'department', headerName: t("Department"), width: 250 },
        { field: 'vendor', headerName: t("Vendor"), width: 180 },
        { field: 'serialNumbers', headerName: "SN", width: 300 },
    ] as GridColDef[], [t]);

    return (
        <TableContainer>
            <DataGridPremium rows={data} columns={columns} slots={{ toolbar: GridToolbar }} slotProps={{ toolbar: { viewKey: "consumptionReport", export: true, } as GridToolbarProps }} loading={isLoading} />
        </TableContainer>
    );
}