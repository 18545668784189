import { List, ListItem, Typography } from "@mui/material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { LayoutContext, fetchGet, fetchPost, newGuid } from "wcz-layout";
import { GridToolbar, GridToolbarProps, TableContainer } from 'wcz-x-data-grid';
import { MaterialCategory } from "../../models/enums/MaterialCategory";
import { eqRoomMaterialUrl } from "../../utils/BaseUrl";
import { Refresh } from "@mui/icons-material";

export default function SafetyStockReport() {
    const { changeTitle, t, i18n, snackbar } = useContext(LayoutContext);

    useEffect(() => changeTitle("Safety stock Report"), []);

    const { data = [], isLoading } = useQuery(["safetyStockReport"], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/report/safetyStock`, signal));

    const { mutate: generate } = useMutation(() => fetchPost(`${eqRoomMaterialUrl}/v1/report/safetyStock/generate`, null), {
        onMutate: () => snackbar({ message: t("GeneratingSafetyStockReport"), description: t("GeneratingSafetyStockReportDescription"), severity: "info" }),
        onError: (error) => console.error(error),
    });

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'alternativePartNumbers', headerName: "PN", width: 160, renderCell: ({ value }) => {
                const values: string[] = value?.split(",");

                return (
                    <List dense>
                        {values.map(value =>
                            <ListItem key={newGuid()} disablePadding>
                                <Typography variant="body2">{value}</Typography>
                            </ListItem>
                        )}
                    </List>
                );
            }
        },
        { field: 'category', headerName: t("Category"), width: 160, type: 'singleSelect', valueOptions: Object.values(MaterialCategory), },
        { field: 'subCategory', headerName: t("SubCategory"), width: 250, },
        { field: 'materialDescription', headerName: t("Description"), width: 380, },
        { field: 'totalQuantity', headerName: t("Quantity"), width: 150, type: "number", },
        { field: 'bufferQuantity', headerName: t("BufferQuantity"), width: 150, type: "number", },
        { field: 'lowestSafetyStock', headerName: t("LowestSafetyStock"), width: 190, type: "number", },
        {
            field: 'models', headerName: t("Models"), width: 160, renderCell: ({ value }) => {
                const values: string[] = value?.split(",");

                return (
                    <List dense>
                        {values?.map(value =>
                            <ListItem key={newGuid()} disablePadding>
                                <Typography variant="body2">{value}</Typography>
                            </ListItem>
                        )}
                    </List>
                );
            }
        },
    ] as GridColDef[], [i18n.resolvedLanguage]);

    const getRowHeight = useCallback(() => "auto", []);

    return (
        <TableContainer>
            <DataGridPremium rows={data} columns={columns} slots={{ toolbar: GridToolbar }} getRowHeight={getRowHeight}
                slotProps={{
                    toolbar: {
                        viewKey: "safetyStockReport", export: true,
                        items: [
                            { title: t("RefreshData"), onClick: () => generate(), icon: <Refresh /> }
                        ]
                    } as GridToolbarProps
                }} loading={isLoading} />
        </TableContainer>
    );
}